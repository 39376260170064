<template>
    <!--begin::Dashboard-->
    <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
        <b-modal id="description-modal" :title="$t('CLASS.DESCRIPTION.LABEL')" button-size="sm">
            <nl2br v-if="classData.description" :text="classData.description" tag="p"/>
            <template #modal-footer="{ok}">
                <b-button variant="secondary" @click="ok()">{{ $t('CONTROLLERS.CLOSE') }}</b-button>
            </template>
        </b-modal>
        <b-modal id="text_certificate-modal" :title="$t('CLASS.CERTIFICATE.LABEL')" button-size="sm">
            <nl2br v-if="classData.text_certificate" :text="classData.text_certificate" tag="p"/>
            <template #modal-footer="{ok}">
                <b-button variant="secondary" @click="ok()">{{ $t('CONTROLLERS.CLOSE') }}</b-button>
            </template>
        </b-modal>
        <!-- Apercu de la classe en haut de la page de modification-->
        <div id="contener" class="d-flex flex-column">
            <div class="align-left mb-10 d-flex flex-row justify-content-between align-items-center">
                <div style="width: 15%">
                    <router-link :to="{ name: 'admin/dashboard', params: { activeTable: 'classes' } }" replace>
                        <span class="btn btn-outline-danger btn-md">
                            <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                            </span>
                            {{ $t('CLASS.BACK') }}
                        </span>
                    </router-link>
                </div>
                <div class="d-flex flex-column align-items-center">
                    <h1 class="mb-1 h3">
                        <span style="font-weight: bold">{{
                                classData.classroom === 1 ? $t('CLASS.NAME.LABELCLASSROOM') : $t('CLASS.NAME.LABEL')
                            }}</span>{{ classData.title }}
                    </h1>
                    <span class="text-muted font-weight-bold d-block">
                        {{
                            $t('CLASS.CREATED_AT', {
                                date: formatDate(classData.created_at),
                                name: classData.creator_name
                            })
                        }}
                    </span>
                </div>
                <div style="width:15%;text-align: end;">
                    <router-link :to="{ name: 'classes/update', params: { id: classData.id } }" replace>
                        <button
                            v-if="!classData.on"
                            v-b-tooltip.hover.left="'Modifier la classe'"
                            class="svg-icon svg-icon-md svg-icon-primary btn btn-md btn-pill btn-outline-secondary mr-2"
                        >
                            <inline-svg src="/media/svg/icons/General/Edit.svg"/>
                        </button>
                    </router-link>
                </div>
            </div>
            <div class="d-flex flex-wrap justify-content-between py-2" style="width: 100%">
                <div style="max-width: 20%;height: 100%">
                    <img
                        ref="EditMiniature"
                        :src="classData.miniature"
                        alt="text"
                        class="m-0 rounded"
                        style="max-width: 100%;max-height:auto;vertical-align:middle"
                    />
                </div>
                <div id="primary" class=" d-flex flex-column justify-content-between px-5"
                     style="width: 50%;max-height: 20em">
                    <div id="description" style="height : 50%">
                        <span class="font-weight-bold">{{ $t('CLASS.DESCRIPTION.LABEL') }}</span>
                        <nl2br
                            v-if="classData.description"
                            :text="classData.description"
                            class-name="customScroll mh-50 my-2 pl-2 border-secondary border-left nl2br"
                            tag="p"
                        />
                        <p v-b-modal.description-modal class="text-primary">
                            Voir la description complète
                        </p>
                    </div>
                    <div id="text" style="height : 50%">
                        <span class="font-weight-bold">{{ $t('CLASS.CERTIFICATE.LABEL') }}</span>
                        <nl2br
                            v-if="classData.text_certificate"
                            :text="classData.text_certificate"
                            class-name="customScroll my-2 pl-2 border-secondary border-left nl2br"
                            tag="p"
                        />
                        <p v-b-modal.text_certificate-modal class="text-primary">
                            Voir le texte complet
                        </p>
                        <b-button class="btn btn-info btn-sm mx-auto" hidden>{{
                                $t('CLASS.DESCRIPTION.INPUT')
                            }}
                        </b-button>
                    </div>
                </div>
                <div class="d-flex flex-column px-2 w-25">
                    <div id="on" class="mb-4">
                        <span v-if="classData.on">
                            <span class="d-flex flex-row justify-content-between align-items-center">
                                <span class="text-primary">{{ $t('CLASS.ON.ONLINE') }}</span>
                                <button
                                    v-b-modal.toogle_post
                                    v-b-tooltip.hover.top="'Rendre la classe indisponible'"
                                    class="btn btn-sm btn-outline-danger"
                                >
                                    {{ $t('CLASS.ON.GO_OFFLINE') }}
                                </button>
                            </span>
                        </span>
                        <span v-else>
                            <span class="d-flex flex-row justify-content-between align-items-center">
                                <span class="text-danger mr-2">{{ $t('CLASS.ON.OFFLINE') }}</span>
                                <button
                                    v-b-modal.toogle_post
                                    v-b-tooltip.hover.top="'Rendre la classe disponible'"
                                    class="btn btn-sm btn-outline-primary"
                                >
                                    {{ $t('CLASS.ON.GO_ONLINE') }}
                                </button>
                            </span>
                        </span>
                        <b-modal
                            id="toogle_post"
                            :title="classData.on ? $t('CLASS.ON.MODAL.TOOGLE_OFF_TITLE') : $t('CLASS.ON.MODAL.TOOGLE_ON_TITLE')"
                            header-text-variant="danger"
                        >
                            <nl2br
                                v-if="classData.on"
                                :text="$t('CLASS.ON.MODAL.TOOGLE_OFF_WARNING') + '\n\n' + $t('CONTROLLERS.CONFIRM')"
                                class="my-4"
                                style="overflow:hidden"
                                tag="p"
                            >
                            </nl2br>
                            <nl2br
                                v-else
                                :text="$t('CLASS.ON.MODAL.TOOGLE_ON_WARNING') + '\n\n' + $t('CONTROLLERS.CONFIRM')"
                                class="my-4"
                                style="overflow:hidden"
                                tag="p"
                            >
                            </nl2br>
                            <template #modal-footer="{cancel}">
                                <b-button variant="danger" @click="toogle_on()">
                                    {{ $t('CONTROLLERS.VALIDATION') }}
                                </b-button>
                                <b-button variant="secondary" @click="cancel()">
                                    {{ $t('CONTROLLERS.CANCEL') }}
                                </b-button>
                            </template>
                        </b-modal>
                        <b-modal
                            id="toogle_post_fail"
                            :title="
                                classData.on ? 'Erreur rencontrée lors de la dépublication' : 'Erreur rencontrée lors de la publication'
                            "
                            header-text-variant="danger"
                        >
                            <nl2br
                                v-if="classData.on"
                                :text="$t('CLASS.ON.MODAL.TOOGLE_OFF_FAIL')"
                                class="my-4"
                                style="overflow:hidden"
                                tag="p"
                            >
                            </nl2br>
                            <nl2br v-else :text="$t('CLASS.ON.MODAL.TOOGLE_ON_FAIL')" class="my-4"
                                   style="overflow:hidden"
                                   tag="p"></nl2br>
                            <template #modal-footer="{ok}">
                                <b-button variant="primary" @click="ok()">
                                    {{ $t('CONTROLLERS.BACK') }}
                                </b-button>
                            </template>
                        </b-modal>
                    </div>
                    <div class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">{{ $t('CLASS.VERSION.LABEL') }}</div>
                        <div class="w-25 text-left">{{ classData.version }}</div>
                    </div>
                    <div v-if="classData.updated_at != undefined" class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">{{ $t('CLASS.UPDATED_AT.DEFINED') }}</div>
                        <div class="w-25 text-left">
                            {{ classData.updated_at != undefined ? formatDate(classData.updated_at) : '' }}
                        </div>
                    </div>
                    <div class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">Classroom :</div>
                        <div class="w-25 text-left">{{ classData.classroom ? 'Oui' : 'Non' }}</div>
                    </div>
                    <div v-if="classData.classroom" class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">Max. stagiaires / session :</div>
                        <div class="w-25 text-left">{{ classData.max_intern }}</div>
                    </div>
                    <div v-if="classData.classroom" class="mb-3 d-flex" v-b-tooltip.hover.top="'Liste des superviseurs'" style="cursor: pointer; text-decoration: underline;" @click="$bvModal.show('superviseursList')">
                        <div class="mr-1 w-75 text-right font-weight-bold">Superviseur{{ classData.superviseurs.length > 1 ? "s" : "" }} :</div>
                        <div class="w-25 text-left">{{ classData.superviseurs.length }}</div>
                    </div>
                    <b-modal id="superviseursList" :title="'Liste des superviseurs'" button-size="sm" hide-footer>
                        <ul>
                            <li v-for="superviseur in classData.superviseurs" :key="superviseur.id">{{ superviseur.user.lastname }} {{ superviseur.user.firstname }}</li>
                        </ul>
                    </b-modal>
                    <div class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">Parcours ordonné :</div>
                        <div class="w-25 text-left">{{ classData.linear ? 'Oui' : 'Non' }}</div>
                    </div>

                    <div class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">{{ $t('CLASS.GOAL.LABEL') }}</div>
                        <div class="w-25 text-left">
                            {{ classData.goal === null ? 'Inderterminé' : classData.goal + '%' }}
                        </div>
                    </div>
                    <div class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">Classe catalogue :</div>
                        <div class="w-25 text-left">{{ classData.catalog === 1 ? 'Oui' : 'Non' }}</div>
                    </div>
                    <div class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">Fiche synthèse :</div>
                        <div class="w-25 text-left">
                            <p v-if="!classData.summary" class="m-0">Aucune</p>
                            <a v-else :href="classData.summary" target="_blank">
                                <span class="svg-icon svg-icon-md mr-4">
                                    <inline-svg src="/media/svg/files/pdf.svg"></inline-svg>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div class="mb-3 d-flex">
                        <div class="mr-1 w-75 text-right font-weight-bold">Thème de formation :</div>
                        <div class="w-25 text-left">
                            <p v-if="!classData.theme" class="m-0">Aucun</p>
                            <p v-else class="m-0">{{ classData.theme.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="stats" class="border my-5" hidden style="width: 100%">
                <div id="student">
                    <div id="end">{{ $t('CLASS.STATS.FINISH') + classe.stats.end }}</div>
                    <div id="running">
                        <p class="font-weight-bold">{{ $t('CLASS.GOAL.WORK') }}</p>
                        {{ classe.stats.running }}
                    </div>
                    <div id="total">
                        <p class="font-weight-bold">{{ $t('CLASS.GOAL.ALL') }}</p>
                        {{ ' => ' + classe.stats.total + ' ' + $t('CLASS.STATS.STUDENT') }}
                    </div>
                </div>
                <div id="success">
                    <p class="font-weight-bold">{{ $t('CLASS.GOAL.SUCCESS') }}</p>
                    {{ classe.stats.success }}
                </div>
            </div>
        </div>
        <div id="lessons" class="mt-10 pt-5 border-top">
            <b-table :fields="fieldsLessons" :items="classData.lessons" borderless class="text-center" hover show-empty>
                <caption>
                    Cours :
                </caption>
                <template #cell(id)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(title)="row">
                    {{ row.item.title }}
                </template>
                <template #cell(description)="row">
                    <nl2br v-if="row.item.description" :text="row.item.description"
                           class-name="customScroll my-2 nl2brLesson"
                           tag="p"/>
                </template>
                <template #cell(linear)="row">
                    <span :class="'svg-icon ' + `${row.item.linear ? 'svg-icon-primary' : 'svg-icon-danger'}`">
                        <inline-svg src="/media/svg/icons/Design/Flatten.svg"/>
                    </span>
                </template>
                <template #cell(min_duration)="row">
                    {{ row.item.min_duration ? row.item.min_duration + ' minutes' : '' }}
                </template>
                <template #cell(synthese)="row">
                    <a v-if="row.item.media" :href="row.item.media" target="_blank">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                            <img src="/media/png/icons/export/PDF_icon.png" height="25px" alt="Icon PDF">
                        </span>
                    </a>
                    <p v-else class="m-0">Aucune</p>
                </template>
                <template #cell(edit)="row">
                    <router-link v-if="!classData.on" :to="{ name: 'lessonItems/editor', params: { id: row.item.id } }"
                                 replace>
                        <button v-b-tooltip.hover.left="'Voir les détails'"
                                class="btn btn-icon btn-circle btn-light btn-sm">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                                <inline-svg src="/media/svg/icons/General/Visible.svg"/>
                            </span>
                        </button>
                    </router-link>
                    <router-link v-else :to="{ name: 'lessonItems', params: { id: row.item.id } }" replace>
                        <button v-b-tooltip.hover.bottom="'Prévisualiser'"
                                class="btn btn-icon btn-circle btn-light btn-sm">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                                <inline-svg src="/media/svg/icons/Media/Play.svg"/>
                            </span>
                        </button>
                    </router-link>
                </template>
                <template #empty>
                    <p class="bg-secondary rounded py-5" disabled>
                        <i class="text-dark">{{ $t('LESSON.EMPTY') }}</i>
                    </p>
                </template>
            </b-table>
            <div v-if="!classData.on" class="d-flex justify-content-around mt-3">
                <router-link
                    :to="{ name: 'lessons/new', params: { id: classData.id, name: classData.title, classroom: classData.classroom } }"
                    replace>
                    <span class="btn btn-outline-primary btn-md">
                        <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/icons/Code/Plus.svg"/>
                        </span>
                        {{ $t('LESSON.ADD') }}
                    </span>
                </router-link>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {LIST_CLASSE} from '@/core/services/store/api/classe.service';
import {mapGetters} from 'vuex';
import ApiService from '@/core/services/api.service';
import i18n from '@/core/plugins/vue-i18n.js';
import moment from 'moment';
import Nl2br from 'vue-nl2br';

export default {
    name: 'admin-edit-classes',
    components: {
        Nl2br
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{title: i18n.t('MENU.DASHBOARD')}]);
        if (this.$route.params.id) {
            this.$store.dispatch(LIST_CLASSE, this.$route.params.id);
        } else {
            this.$router.push({name: 'admin/dashboard'});
        }
    },
    computed: {
        ...mapGetters({classData: 'getClasse'})
    },
    data() {
        return {
            classe: {
                stats: {
                    total: 6000,
                    running: 2560,
                    end: 3440,
                    success: 61
                }
            },
            fieldsLessons: [
                {
                    key: 'id',
                    label: '#',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'width: 50px'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 200px'
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 200px'
                },
                {
                    key: 'linear',
                    label: 'Cours ordonné',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 75px'
                },
                {
                    key: "synthese",
                    label: "Fiche synthèse",
                },
                {
                    key: 'edit',
                    label: '',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 75px'
                }
            ],
            show: true
        };
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        toogle_on() {
            this.$bvModal.hide('toogle_post');
            ApiService.post(process.env.VUE_APP_API_URL + '/academy/classes/on', {id: this.classData.id})
                .then(result => {
                    if (parseInt(result.data) === 1) {
                        this.$store.dispatch(LIST_CLASSE, this.classData.id).then(() => {
                            this.$forceUpdate();
                        });
                    } else {
                        this.$bvModal.show('toogle_post_fail');
                    }
                })
                .catch(error => {
                    this.errorMessage = error.message;
                });
        }
    },
    watch: {
        classData: function () {
            if (this.classData.classroom) {
                this.fieldsLessons.splice(4, 0, {
                    key: 'min_duration',
                    label: 'Durée minimale',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 200px'
                });
            }
        }
    }
};
</script>
<style scoped>
.nl2br {
    max-height: 45%;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}

.nl2brLesson {
    max-height: 7em;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}

.customScroll::-webkit-scrollbar-track {
    background-color: #eee;
}

.customScroll::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #0000005b;
}
</style>
